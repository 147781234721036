import { StoredEditorComponent } from "../../types/graphql";
import { RenderElement } from "./Component";

export function Renderer({
  content,
  setSelectedComponent,
}: {
  content: StoredEditorComponent[];
  setSelectedComponent: (o: string) => void;
}) {
  return (
    <>
      {content.map((o) => (
        <RenderElement
          element={o}
          children={o.children.map((o) => o.text).join("")}
          attributes={null}
          onClick={() => {
            setSelectedComponent(o.key);
          }}
          key={o.key}
          componentKey={o.key}
        ></RenderElement>
      ))}
    </>
  );
}
