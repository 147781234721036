import { gql } from "@apollo/client";
import { add, formatDistanceToNow } from "date-fns";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import client from "../apollo-client";
import Comments from "../components/Comments/Comments";
import Countdown from "../components/Countdown";
import { Renderer } from "../components/EditorNew/Renderer";
import Loading from "../components/Loading";
import MetaHeader from "../components/MetaHeader";
import PageTemplate from "../components/PageTemplate";
import StyledLink from "../components/StyledLink";
import { useIsMobile } from "../hooks/isMobile.hook";
import { Color } from "../theme/theme";
import { Article } from "../types/graphql";
import { extractImage, extractRawText } from "../utilities/article.utilites";
import { ARTICLE_FRAGMENT } from "./create";

export default function Post({
  content,
  author,
  published,
  title,
  _id,
  isDeleted,
}: Article) {
  const router = useRouter();
  const isMobile = useIsMobile();
  const [selectedComponent, setSelectedComponent] = useState("");
  useEffect(() => {
    setSelectedComponent(content?.[0]?.key);
  }, [content]);

  if (router.isFallback) {
    return <Loading />;
  }
  if (isDeleted) {
    return <div>Story is gone</div>;
  }

  if (content) {
    const displayDate = formatDistanceToNow(new Date(published || null));
    const rawText = extractRawText(content);
    const image = extractImage(content);

    return (
      <>
        <MetaHeader
          title={`DecodedBlog - ${title}`}
          author={author.name}
          description={rawText}
          image={image || author.image}
        />
        <PageTemplate style={{ backgroundColor: Color.sand, padding: "0px" }}>
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <div
              style={{
                flex: "1 90%",
                padding: isMobile ? "12px" : "48px",
                maxWidth: "1000px",
                overflowY: "auto",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    marginRight: "12px",
                    borderRadius: "50%",
                    height: "48px",
                    width: "48px",
                    objectFit: "cover",
                  }}
                  src={author.image}
                />
                <span>
                  <StyledLink href={`/user/${author.username}`}>
                    {author.name}
                  </StyledLink>{" "}
                  <i style={{ color: Color.dark, fontWeight: "300" }}>
                    posted {displayDate} ago.
                  </i>
                </span>
              </div>
              <div
                style={{
                  color: Color.dark,
                  fontWeight: "300",
                  paddingLeft: "60px",
                }}
              >
                <i>
                  <span
                    style={{
                      fontWeight: "200",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    You have 🔥
                    {
                      <Countdown
                        endDate={add(new Date(published), {
                          weeks: 1,
                        })}
                      ></Countdown>
                    }
                    🔥 to read this before its gone forever 🗑
                  </span>
                </i>
              </div>
              <Renderer
                setSelectedComponent={(key) => setSelectedComponent(key)}
                content={content}
              />
            </div>
            {selectedComponent && (
              <div
                style={{
                  flex: "1 10%",
                  backgroundColor: Color.white,
                  height: "100%",
                }}
              >
                <Comments
                  setSelectedComponent={(key) => setSelectedComponent(key)}
                  articleId={_id}
                  currentKey={selectedComponent}
                  content={content}
                />
              </div>
            )}
          </div>
        </PageTemplate>
      </>
    );
  }
}
export async function getStaticProps({
  params: { slug },
}: {
  params: { slug: [string] };
}) {
  const { data, error } = await client.query<{ article: Article }>({
    query: gql`
      ${ARTICLE_FRAGMENT}
      query Post($input: ArticleFilter) {
        article(input: $input) {
          ...CoreArticleFields
          published
        }
      }
    `,
    variables: {
      input: { slug: slug[0] },
    },
  });
  return {
    props: JSON.parse(JSON.stringify(data.article)),
    notFound: !data, //use this to say when it 4o4o
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every second
    revalidate: 1, // In seconds
  };
}
export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}
