import { Article } from "../../types/graphql";
import { ComponentTypeToString } from "../EditorNew/editor.constants";
import { getText } from "../EditorNew/editor.utilities";

function trimToShortText(string: string) {
  if (string) {
    const length = 25;
    return string.length > length
      ? string.substring(0, length - 3).trim() + "..."
      : string;
  }
  return false;
}

export function generateTitle(
  content: Article["content"],
  key: string
): string {
  const currentComponent = content.find((o) => o.key === key);
  if (currentComponent) {
    const text = getText(currentComponent);
    return `${ComponentTypeToString[currentComponent.type]} ${
      text ? `- ${trimToShortText(text)}` : ``
    }`;
  }
  return `None selected`;
}
