import { ComponentType, StoredEditorComponent } from "../types/graphql";

export function extractRawText(content: StoredEditorComponent[]) {
  const textArr = content
    ?.filter((o) => o?.type === ComponentType.Text)
    ?.map((o) => o.children?.map((o) => o.text).join(""));
  return textArr?.join("\n") || "";
}

export function extractImage(content: StoredEditorComponent[]) {
  return content.find((o) => o.type === ComponentType.Image)?.props?.image?.url;
}

export function trimToShortText(string: string) {
  const length = 155;
  return string.length > length
    ? string.substring(0, length - 3) + "..."
    : string;
}
