import Image from "next/image";
export default function Loading(): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "200px" }}>
        <Image src="/loading.gif" alt="loading" width="200" height="200" />
      </div>
    </div>
  );
}
