import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { formatDistanceToNow } from "date-fns";
import { signIn, useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { useIsMobile } from "../../hooks/isMobile.hook";
import { Color } from "../../theme/theme";
import {
  Article,
  Comment,
  MutationUpdateCommentArgs,
  QueryCommentsArgs,
} from "../../types/graphql";
import GenericButton from "../Button";
import GenericInput from "../Input";
import StyledLink from "../StyledLink";
import { generateTitle } from "./comments.utilities";

const QUERY = gql`
  query Comments($input: CommentsFilter!) {
    comments(input: $input) {
      author {
        username
        name
        image
      }
      updatedAt
      content
    }
  }
`;

const MUTATION = gql`
  mutation Comment($input: CommentInput!) {
    updateComment(input: $input) {
      _id
    }
  }
`;

export default function Comments({
  articleId,
  currentKey,
  content,
  setSelectedComponent,
}: {
  open?: boolean;
  articleId: string;
  currentKey: string;
  content: Article["content"];
  setSelectedComponent: (o: string) => void;
}) {
  const { data: session, status } = useSession();
  const isMobile = useIsMobile();
  const [commentText, setCommentText] = useState("");
  const [hide, setHide] = useState(false);
  const [loadData, { data: { comments } = { comments: [] } }] = useLazyQuery<
    {
      comments: Comment[];
    },
    QueryCommentsArgs
  >(QUERY, {
    variables: {
      input: { componentKey: currentKey },
    },
  });

  useEffect(() => {
    loadData();
  }, [currentKey]);

  const [updateComment] = useMutation<MutationUpdateCommentArgs>(MUTATION, {
    refetchQueries: [
      {
        query: QUERY,
        variables: {
          input: { componentKey: currentKey },
        },
      },
      "index",
    ],
  });

  function makeComment() {
    if (commentText !== "") {
      updateComment({
        variables: {
          input: {
            content: commentText,
            componentKey: currentKey,
            articleId: articleId,
          },
        },
      });
      setCommentText("");
    }
  }

  return (
    <div
      style={{
        padding: "12px",
        position: "sticky",
        top: "0px",
        ...(isMobile ? {} : { minWidth: "300px" }),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            margin: "0px",
            paddingBottom: "12px",
          }}
        >
          Comments on{" "}
          <span style={{ fontWeight: 200 }}>
            {generateTitle(content, currentKey)}
          </span>
        </h3>
        {isMobile && (
          <GenericButton onClick={() => setHide(!hide)}>
            {hide ? "show" : "hide"}
          </GenericButton>
        )}
      </div>
      {!hide && (
        <div>
          {comments && (
            <div
              style={
                isMobile
                  ? {
                      maxHeight: "50vh",
                      display: "flex",
                      flexDirection: "column",
                    }
                  : {}
              }
            >
              <div style={{ overflowY: "auto" }}>
                {comments.map((comment) => (
                  <div style={{ paddingBottom: "12px" }}>
                    <div>
                      <i
                        style={{
                          color: Color.grey,
                          fontWeight: "200",
                          fontSize: "12px",
                        }}
                      >
                        <StyledLink href={`/user/${comment.author.username}`}>
                          <span>{comment.author.name} </span>
                        </StyledLink>{" "}
                        posted{" "}
                        {formatDistanceToNow(new Date(comment.updatedAt))} ago
                      </i>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "6px",
                        paddingTop: "6px",
                        fontSize: "14px",
                      }}
                    >
                      <img
                        style={{
                          marginRight: "6px",
                          borderRadius: "50%",
                          height: "16px",
                          width: "16px",
                          objectFit: "cover",
                        }}
                        src={comment.author.image}
                      />
                      <div>{comment.content}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                  width: "100%",
                }}
              >
                {session && (
                  <GenericInput
                    value={commentText}
                    placeholder="Add a comment..."
                    onChange={(e) => setCommentText(e.target.value)}
                    onClick={() => makeComment()}
                    buttonText="Send"
                  />
                )}
                {!session && (
                  <div>
                    Must be logged in to make comments{" "}
                    <div style={{ paddingTop: "12px" }}>
                      <GenericButton onClick={() => signIn("auth0")}>
                        Sign In
                      </GenericButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
